import { ErrorMsg, LabelText } from "../../components";
import { ErrorMessage, Field } from "formik";
import { FormInputI } from "../../types/formTypes";
import cs from "classnames";
import "./formInput.scss";

const FormInput: React.FC<FormInputI> = ({
  name,
  placeholder,
  label,
  type = "text",
  className,
  inputRef,
  labelVariant,
}) => {
  const classes = cs("formInput", {
    className,
  });

  return (
    <div className={classes}>
      {label && (
        <LabelText variant={labelVariant} id={name}>
          {label}
        </LabelText>
      )}
      <Field
        name={name}
        id={name}
        type={type}
        className="formInput__input"
        placeholder={placeholder}
        innerRef={inputRef}
      />
      <ErrorMessage name={name} component={ErrorMsg} />
    </div>
  );
};

export default FormInput;
