import { Box, Checkbox, Text } from "components";
import React, {useState} from "react";
import "./notificationRow.scss";
import cs from "classnames";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { handleShownStatus } from "utils/handleShownStatus";
import { IElectricityTransaction } from 'interfaces/electricity';
import { ElectricityTransactionStatus } from "shareable/enums";
import { handleGetRequest } from 'services/http/index';
import { toast } from 'react-toastify';
import { formatCurrency, nairaSymbol } from 'utils/formatCurrency';

interface NotificationRowProps {
  transaction:IElectricityTransaction
  showCondition?: boolean;
  showRequery: boolean
  onCheck?: (transaction:IElectricityTransaction) => void,
  isChecked?: boolean,
  showCheck?: boolean
}

const NotificationRow: React.FC<NotificationRowProps> = ({
  transaction,
  showCondition,
  showRequery,
  onCheck, 
  isChecked,
  showCheck
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isRefunding, setIsRefundinmg] = useState(false)
  const navigate = useNavigate();

  const fomattedDate = moment(transaction.createdAt).format("DD/MM/YYYY");
  const formattedTime = moment(transaction.createdAt).format("h:mm a");

  const handleRoute = () => navigate(`/transactions/${transaction._id}`);

  const classes = cs("notificationRow", {
    failed: transaction.status === ElectricityTransactionStatus.error,
    pending: transaction.status === ElectricityTransactionStatus.pending,
    success: transaction.status === ElectricityTransactionStatus.success,
    condition: showCheck,
  });
  const handleRequery = async (e:React.MouseEvent) => {
    e.stopPropagation()
    setIsLoading(true)
    try {
      const res = await handleGetRequest<unknown>(`/electricity/transactions/requery/${transaction._id}`)
      toast.success(res.message);
    } catch (error:any) {
      toast.error(error.rsponse.data.message);
    }finally {
      setIsLoading(false)
    }
  }

  const handleRefund = async (e:React.MouseEvent) => {
    e.stopPropagation()
    setIsRefundinmg(true)
    try {
      const res = await handleGetRequest<unknown>(`/electricity/transactions/${transaction._id}/refund`)
      toast.success(res.message);
    } catch (error:any) {
      toast.error(error.rsponse.data.message);
    }finally {
      setIsRefundinmg(false)
    }
  }
  const handleCheck = (e:React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()

    onCheck!(transaction)
  }
  //onClick={handleRoute}
  return (
    <Box className={classes} onClick={handleRoute} >
     
      <Box className="row" onClick={e => e.stopPropagation()}>
      {
        showCheck && <Box onClick={e => e.stopPropagation()}> <Checkbox onChange={handleCheck} checked={isChecked!} /> </Box>}
         <Text.Small className="notificationRow__item">
         {transaction.user?.firstName} {transaction.user?.lastName}
        </Text.Small>
        
      </Box>
     
      <Text.Small className="notificationRow__item">{fomattedDate}</Text.Small>
      <Text.Small className="notificationRow__item">{formattedTime}</Text.Small>
      <Text.Small className="notificationRow__item">{nairaSymbol}{ formatCurrency(transaction.amount.toString())}</Text.Small>
      <Text.Small className="notificationRow__item">{transaction.serviceProvider}</Text.Small>
      <Text.Small className="notificationRow__item">{transaction.phoneNumber}</Text.Small>
      {/* <Text.Small className="notificationRow__item">{transaction.user.email}</Text.Small> */}
      <Text.Small className="notificationRow__item">
        {transaction.status}
      </Text.Small>
      {showRequery && transaction.status.toLowerCase() === ElectricityTransactionStatus.pending!.toLowerCase() && (
        <button className="requery-btn" onClick={handleRequery} disabled={isLoading}>{isLoading ? "Processing..." : "Requery"}</button>
      )}
      {/* {showRequery && transaction.status.toLowerCase() === ElectricityTransactionStatus.pending!.toLowerCase() && (
        <button className="requery-btn refundBtn" onClick={handleRefund} disabled={isLoading}>{isRefunding ? "Processing..." : "Refund"}</button>
      )} */}
    </Box>
  );
};

export default NotificationRow;
