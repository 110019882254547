import { Box, Text } from "components";
import React, { ReactNode } from "react";
import "./header.scss";

interface Props {
  header: ReactNode;
}

const MainHeader: React.FC<Props> = ({ header }) => {
  return (
    <Box className="mainHeader" as="header">
      <Box className="mainHeader__container">
        <Text.H2 className="mainHeader__text">{header}</Text.H2>
      </Box>
    </Box>
  );
};

export default MainHeader;
