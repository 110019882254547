import {AxiosConfig as API} from 'config/AxiosConfig';
import {IHttpResponse} from 'interfaces/httpResponse';

export const handleGetRequest = async <T>(
  payload: string,
): Promise<IHttpResponse<T>> => {

  try {
    const {data} = await API.get(payload);
    return data;
  } catch (err) {
    console.log(err, 'ERR');
    throw err;
  }
};

export const handlePostRequest = async <T, G>(
  path: string,
  payload: T,
): Promise<IHttpResponse<G>> => {
  try {
    
    const {data} = await API.post(`${path}`, payload);
    return data;
  } catch (err) {
    throw err;
  }
};
export const handlePuttRequest = async <T, G>(
  path: string,
  payload: T,
): Promise<IHttpResponse<G>> => {
  try {
  
    const {data} = await API.put(`${path}`, payload);

    return data;
  } catch (err) {
    throw err;
  }
};
export const handlePatchRequest = async <T, G>(
  path: string,
  payload: T,
): Promise<IHttpResponse<G>> => {
  try {
   
    const {data} = await API.patch(`${path}`, payload);

    return data;
  } catch (err) {
    throw err;
  }
};
export const handleDeleteRequest = async <T>(
  payload: string,
): Promise<IHttpResponse<T>> => {
  try {
    const {data} = await API.delete(`${payload}`);
    return data;
  } catch (err) {
    throw err;
  }
};
