import {
  Form,
  Formik,
  FormikConfig,
  FormikHelpers,
  FormikValues,
} from "formik";
import React, { useState } from "react";
import { FormStepI } from "../../types/formTypes";

import "./multiStepWrapper.scss";

interface Props extends FormikConfig<FormikValues> {
  children: React.ReactNode;
}

const MultiStepWrapper: React.FC<Props> = ({
  children,
  initialValues,
  onSubmit,
}) => {
  // this is the state for current steps
  const [stepNumber, setStepNumber] = useState(0);

  // new initial values based on the current step
  const [newInitialValues, setNewInitialValues] = useState(initialValues);

  //   this converts the children into an array
  const steps = React.Children.toArray(children) as React.ReactElement[];

  // step = current child
  const step = steps[stepNumber];
  // total number of steps
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  // function to trigger next
  const next = (values: FormikValues) => {
    setStepNumber(stepNumber + 1);
    setNewInitialValues(values);
  };

  // function to go back
  const prev = (values: FormikValues) => {
    setStepNumber(stepNumber - 1);
    setNewInitialValues(values);
  };

  // function for handling submit
  const handleSubmit = async (
    values: FormikValues,
    helpers: FormikHelpers<FormikValues>
  ) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values);
    }

    if (isLastStep) {
      return onSubmit(values, helpers);
    } else {
      helpers.setTouched({});
      next(values);
    }
  };

  const validationSchema = step.props.validationSchema;

  return (
    <Formik
      initialValues={newInitialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form>
          <Stepper steps={steps} stepNumber={stepNumber} />
          {step}
          <FormNavigation
            isLastStep={isLastStep}
            hasPrevious={stepNumber > 0}
            onBackClick={() => prev(formik.values)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default MultiStepWrapper;

interface FormNavigationI {
  hasPrevious?: boolean;
  onBackClick: (values: FormikValues) => void;
  isLastStep: boolean;
}

const FormNavigation: React.FC<FormNavigationI> = ({
  hasPrevious,
  onBackClick,
  isLastStep,
}) => {
  return (
    <div className="formNavigation">
      {hasPrevious && (
        <button type="button" onClick={onBackClick}>
          Back
        </button>
      )}
      <button type="submit">{isLastStep ? "Submit" : "Next"}</button>
    </div>
  );
};

interface StepperI {
  steps: React.ReactElement[];
  stepNumber: number;
}

const Stepper: React.FC<StepperI> = ({ steps, stepNumber }) => {
  return (
    <div className={`stepper`}>
      {steps.map((item, index) => {
        const stepName = item.props.stepName;
        const isActive = steps.indexOf(item) <= stepNumber;

        return (
          <div
            key={index}
            className={`stepper__div ${isActive ? "active" : ""}`}
          >
            {stepName}
          </div>
        );
      })}
    </div>
  );
};

export const FormStep: React.FC<FormStepI> = ({
  stepName = "",
  validationSchema,
  onSubmit,
  children,
}: any) => children;
