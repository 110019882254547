import "./loader.scss";

const Loader = () => {
  return (
    <div className={`loader`}>
      <div className="loader__imgDiv">
        <img className="loader__img" src="/images/letterP.png" alt="" />
      </div>
    </div>
  );
};

export default Loader;
