import cs from "classnames";
import { ErrorMessage, Field } from "formik";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import { ErrorMsg, LabelText } from "../../components";
import { FormSelectI } from "../../types/formTypes";
import "./formSelect.scss";

interface sortedDataI {
  value: string;
  label: string;
  id: string;
}

const FormSelect: React.FC<FormSelectI> = ({
  label,
  name,
  data,
  optionLabel,
  optionValue,
  className,
  emptyOptionText,
}) => {
  const [sortedData, setSortedData] = useState<sortedDataI[]>([]);

  useEffect(() => {
    const opts = data.map((item) => {
      let optionData: sortedDataI = {
        value: item[optionValue],
        label: item[optionLabel],
        id: v4(),
      };

      return optionData;
    });

    setSortedData(opts);
  }, [data, optionLabel, optionValue]);

  const classes = cs("formSelect", { className });

  return (
    <div className={classes}>
      {label && <LabelText id={name}>{label}</LabelText>}
      <Field name={name} as="select" className="formSelect__select">
        {emptyOptionText && <option value="">{emptyOptionText}</option>}
        {sortedData.map((item) => (
          <option
            key={item.id}
            value={item.value}
            className="formSelect__option"
          >
            {item.label}
          </option>
        ))}
      </Field>
      <ErrorMessage name={name} component={ErrorMsg} />
    </div>
  );
};

export default FormSelect;
