import {
  H1,
  H2,
  H3,
  H4,
  Large,
  Paragraph,
  SectionHeader,
  Small,
  Smaller,
} from "./modules";
import { TextProps } from "./types/textTypes";

const Text = (props: TextProps) => <Paragraph {...props} />;
Text.H1 = (props: TextProps) => <H1 {...props} />;
Text.H2 = (props: TextProps) => <H2 {...props} />;
Text.H3 = (props: TextProps) => <H3 {...props} />;
Text.H4 = (props: TextProps) => <H4 {...props} />;
Text.Small = (props: TextProps) => <Small {...props} />;
Text.Smaller = (props: TextProps) => <Smaller {...props} />;
Text.Large = (props: TextProps) => <Large {...props} />;
Text.SectionHeader = (props: TextProps) => <SectionHeader {...props} />;

export default Text;
