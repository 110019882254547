import { TextProps } from "../../types/textTypes";
import "./paragraph.scss";

const Paragraph: React.FC<TextProps> = ({ children, className }) => {
  return (
    <p className={`paragraph ${className ? className : ""}`}>{children}</p>
  );
};

export default Paragraph;
