import { Box, MetaData } from "components";
import React, { ReactNode } from "react";
import MainContent from "./components/mainContent";
import SideNav from "./components/sideNav";
import "./contentMgt.scss";

interface Props {
  header: ReactNode;
  children: ReactNode;
  pageTitle: string;
  fixedHeight?: boolean;
}

const ContentMgtLayout: React.FC<Props> = ({
  children,
  header,
  pageTitle,
  fixedHeight,
}) => {
  return (
    <>
      <MetaData pageTitle={pageTitle} />
      <Box className="contentMgt">
        <SideNav />
        <MainContent header={header} fixedHeight={fixedHeight}>
          {children}
        </MainContent>
      </Box>
    </>
  );
};

export default ContentMgtLayout;
