import { Box } from "components/box";
import NotificationRow from "components/notificationRow";
import Skeleton from "components/Skeleton";
import { Text } from "components/text";
import React, { useState } from "react";
import { TransactionDataI } from "types/client";
import { GiEmptyHourglass } from "react-icons/gi";
import cs from "classnames";
import "./notificationTable.scss";
import { IElectricityTransaction } from 'interfaces/electricity';
import { useArchiveTicket, useRaiseTicket } from '../../hooks/queries/transactions';
import { toast } from 'react-toastify';
import queryClient  from 'config/reactQuery';
import Checkbox from "components/checkbox/Checkbox";

interface Props {
  data: IElectricityTransaction[];
  loading?: boolean;
  showCondition?: boolean;
  showRequery:boolean;
  showCheck?:boolean
  showStart?:boolean;
  showArchive?:boolean
}

const NotificationTable: React.FC<Props> = ({
  data,
  loading,
  showCondition,
  showRequery,
  showCheck,
  showStart,
  showArchive
}) => {
  const [selected, setSelected ] = useState<string[]>([])
  const [actioning, setActioning] = useState(false)
  const [masterChecked, setmasterChecked] = useState(false)

  const {isLoading:raisingTicket, mutate:raiseTicket} = useRaiseTicket(false)
  const {isLoading:archivingTicket, mutate:archiveTicket} = useArchiveTicket(false)

  const isSelected = (trx:IElectricityTransaction) => {
    return selected.includes(trx._id)
  }
  const removeSelected = (trx:IElectricityTransaction) => {
    const updated = selected.filter(id => id!=trx._id)
    setSelected(updated)
  }
  const selectItem = (trx:IElectricityTransaction) => {
    setSelected(selected.concat(trx._id))
  }
  const handleCheck = (trx:IElectricityTransaction) => {
    if(isSelected(trx)){
      removeSelected(trx)
    } else {
      selectItem(trx)
    }
  }
  const handleStart = async () => {
    setActioning(true)
    for (let trx of selected){
      await raiseTicket(trx)
    }
    setActioning(false)
    setSelected([])
    toast.success(`${selected.length} raised`)
    queryClient.invalidateQueries()
  }
  const handleAchive = async () => {
    setActioning(true)
    for (let trx of selected){
      await archiveTicket(trx)
    }

    setActioning(false)
    setSelected([])
    toast.success(`${selected.length} archived`)
    queryClient.invalidateQueries()
  }

  const handleMasterCheck = (e:React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    if(masterChecked){
      setSelected([])
    } else {
      const ids = []
      for(let record of data){
        ids.push(record._id)
      }
     
      setSelected(ids)
    }
    
    setmasterChecked(!masterChecked)
  }
  return (
    <Box className="notificationTable">
      {
        selected.length ?  <div className="tableActions">
        {showStart && <button className="startBtn" disabled={actioning} onClick={handleStart}  >{raisingTicket ? "Processing..." : "Raise Ticket"}</button>}
       { showArchive && <button className="achiveBtn" disabled={actioning} onClick={handleAchive}  >{archivingTicket ? "Processing..." : "Archive"}</button>}
        </div> : null
      }
       
      <Box
        className={cs("notificationTable__head", {
          condition: showCheck,
        })}
      >
        {/* {
          showCheck && <Box>
            <Checkbox onChange={handleMasterCheck} checked={masterChecked} />
          </Box>
        } */}
        <Box className="row">
        {
          showCheck && <Box>
            <Checkbox onChange={handleMasterCheck} checked={masterChecked} />
          </Box>
        }
        <Text className="notificationTable__headItem">Name</Text>
        </Box>
        
        <Text className="notificationTable__headItem">Date</Text>
        <Text className="notificationTable__headItem">Time</Text>
        <Text className="notificationTable__headItem">Amount</Text>
        <Text className="notificationTable__headItem">Disco</Text>
        <Text className="notificationTable__headItem">Phone Number</Text>
        {/* <Text className="notificationTable__headItem">Email</Text> */}
        <Text className="notificationTable__headItem">Status</Text>
        {showRequery && (
          <Text className="notificationTable__headItem">Requery</Text>
        )}
        
      </Box>
      { loading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) :
      <>
    
      {data &&
        !loading &&
        data.map((item) => (
          <NotificationRow
            showCondition={showCondition}
            transaction={item}
            key={item._id}
            showRequery={showRequery}
            showCheck={showCheck}
            isChecked={isSelected(item)}
            onCheck={handleCheck}

          />
        ))}
        </>
      }
      {!data.length && !loading && (
        <Box className="notificationTable__empty">
          <GiEmptyHourglass className="notificationTable__emptyIcon" />
          <Text.H3 className="notificationTable__emptyText">
            No data found
          </Text.H3>
        </Box>
      )}
      
    </Box>
  );
};

export default NotificationTable;
