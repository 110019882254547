import { useAuth } from "contexts/AuthContext";
import { useMutation, useQuery, UseQueryOptions } from "react-query";
import {
  getArchivedTransactions,
  getDashboardTransactions,
  getPreviousTransactionByUser,
  getSingleTransaction,
  getTicketingTransactions,
  getTransactions,
  getTransactionsByStatus,
  retryTransaction,
  updateCondition,
} from "services/transactions";
import { NewTransactionDataI } from "types/client";
import { toast } from "react-toastify";
import { handleGetRequest } from 'services/http/index';
import { GET_SINGLE_TRANSACTION, RAISE_TICKET_API } from "api/electricity";
import { IElectricityTransaction } from "interfaces/electricity";
import { handlePostRequest } from 'services/http';
import { ARCHIOVE_TICKET_API } from '../../api/electricity';
import { IHttpError } from 'interfaces/httpError';
import queryClient from '../../config/reactQuery';

export const useTransactions = () => {
  const { state } = useAuth();
  return useQuery("transactions", () =>
    getTransactions(state?.token || "")
  );
};

export const useDashboardTransactions = (
  options?: UseQueryOptions<NewTransactionDataI[]>
) => {
  const { state } = useAuth();
  return useQuery({
    queryKey: ["dashboardTransactions"],
    queryFn: () => getDashboardTransactions(state?.token || ""),
    ...options,
  });

  // return useQuery("dashboardTransactions", () =>
  //   getDashboardTransactions(state?.user.token || "")
  // );
};

export const useTicketingTransactions = () => {
  const { state } = useAuth();
  return useQuery("ticketingTransactions", () =>
    getTicketingTransactions(state?.token || "")
  );
};

export const useArchivedTransactions = () => {
  const { state } = useAuth();
  return useQuery("dashboardTransactions", () =>
    getArchivedTransactions(state?.token || "")
  );
};

export const useSingleTransaction = (id: string | number) => {
  const {isLoading, data} = useQuery(["singleTransactions", id], async () =>
    handleGetRequest<IElectricityTransaction>(`${GET_SINGLE_TRANSACTION}${id}`)
  );

  return {isLoading, data}
};

export const usePreviousTransactionsByUser = (
  id: string,
  options?: UseQueryOptions<NewTransactionDataI[]>
) => {
  const { state } = useAuth();

  return useQuery({
    queryKey: ["getPreviousTransactionByUser", id],
    queryFn: () => getPreviousTransactionByUser(id, state?.token || ""),
    ...options,
  });
};
export const useRaiseTicket = (showToast:boolean = true) => {
  return useMutation(
   async  (id:string) => await handlePostRequest(`${RAISE_TICKET_API}${id}`, {}),
    {
      onSuccess: (data) => {
      showToast &&  toast.success(data.message);
        queryClient.invalidateQueries()
      },
      onError: (error:IHttpError) => {
        toast.error(error.response.data.message);
      },
    }
  );
};
export const useArchiveTicket = (showToast:boolean=true) => {
  return useMutation(
   async  (id:string) => await handlePostRequest(`${ARCHIOVE_TICKET_API}${id}`, {}),
    {
      onSuccess: (data) => {
       showToast && toast.success(data.message);
        queryClient.invalidateQueries()
      },
      onError: (error:IHttpError) => {
        toast.error(error.response.data.message);
      },
    }
  );
};
export const useUpdateTransaction = (id: string | number, status: string) => {
  const { state } = useAuth();
  return useMutation(
    () => updateCondition(id, status, state?.token || ""),
    {
      onSuccess: (data) => {
        toast.success("Condition updated!");
      },
      onError: () => {
        toast.error("Something went wrong!");
      },
    }
  );
};

export const useRetryTransaction = (
  reference: string,
  options?: UseQueryOptions
) => {
  const { state } = useAuth();

  return useQuery({
    queryKey: ["retryTransaction", reference],
    queryFn: () => retryTransaction(reference, state?.token || ""),
    ...options,
  });
};

export const useGetTransactionByStatus = (
  status: string,
  options?: UseQueryOptions
) => {
  const { state } = useAuth();

  return useQuery({
    queryKey: ["retryTransaction", status],
    queryFn: () => getTransactionsByStatus(status, state?.token || ""),
    ...options,
  });
};
