import cs from "classnames";
import { ErrorMessage, Field, FieldProps } from "formik";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import { ErrorMsg, LabelText } from "../../components";
import { FormRadioI } from "../../types/formTypes";
import "./formRadio.scss";

interface sortedDataI {
  value: string;
  label: string;
  id: string;
}

const FormRadio: React.FC<FormRadioI> = ({
  label,
  name,
  data,
  optionLabel,
  optionValue,
  className,
  labelVariant,
}) => {
  const [sortedData, setSortedData] = useState<sortedDataI[]>([]);

  useEffect(() => {
    const opts = data.map((item) => {
      let optionData: sortedDataI = {
        value: item[optionValue],
        label: item[optionLabel],
        id: v4(),
      };

      return optionData;
    });

    setSortedData(opts);
  }, [data, optionLabel, optionValue]);

  const classes = cs("formRadio", { [`${className}`]: className });

  return (
    <div className={classes}>
      {label && (
        <LabelText variant={labelVariant} id={name}>
          {label}
        </LabelText>
      )}
      <Field name={name}>
        {({ field }: FieldProps) => {
          return (
            <div className="formRadio__radios">
              {sortedData.map((item) => (
                <div key={item.id} className="formRadio__radio">
                  <input
                    className="formRadio__input"
                    type="radio"
                    id={item.id}
                    {...field}
                    value={item.value}
                    checked={field.value === item.value}
                  />
                  <label className="formRadio__label" htmlFor={item.id}>
                    {item.label}
                  </label>
                </div>
              ))}
            </div>
          );
        }}
      </Field>
      <ErrorMessage name={name} component={ErrorMsg} />
    </div>
  );
};

export default FormRadio;
