import React, { ReactNode } from "react";
import { CSVLink } from "react-csv";
import { Headers, Data } from "react-csv/components/CommonPropTypes";

interface Props {
  children: ReactNode;
  fileName: string;
  header: Headers;
  data: Data;
}

const CsvBtn: React.FC<Props> = ({ children, header, data, fileName }) => {
  return (
    <CSVLink headers={header} data={data} filename={fileName}>
      {children}
    </CSVLink>
  );
};

export default CsvBtn;
