import { IUser } from "interfaces/user";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { getCurrentUser, getAccessToken } from "utils/localStorage";

interface AuthContextI {
  state: StateI;
  dispatch: React.Dispatch<ActionI>;
}

const AuthContext = createContext<AuthContextI | null>(null);

interface StateI {
  isAuthenticated: boolean;
  user: IUser | undefined,
  token: string
}

export enum ActionTypes {
  login = "LOGIN",
  logout = "LOGOUT",
}

interface ActionI {
  type: ActionTypes;
  payload?: any;
}

const initialState: StateI = {
  isAuthenticated: false,
  user:undefined,
  token: ""
};

const reducer = (state: StateI, action: ActionI) => {
  switch (action.type) {
    case ActionTypes.login:
      return {
        isAuthenticated: true,
        user: action.payload?.user,
        token:action.payload?.token
      };

    case ActionTypes.logout:
      return {
        isAuthenticated: false,
        user: undefined,
        token:""
      };

    default:
      return state;
  }
};

interface Props {
  children?: ReactNode;
}

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const currentUser = getCurrentUser()
    if (currentUser) {
      const authData = {
        user: currentUser,
        token: getAccessToken()
      }
      dispatch({ type: ActionTypes.login, payload: authData });
    } else {
      dispatch({ type: ActionTypes.logout });
    }
  }, []);

  const value = {
    state,
    dispatch,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  return { ...authContext };
};
