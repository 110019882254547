import cs from "classnames";
import { ErrorMessage, Field, FieldProps } from "formik";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import { ErrorMsg, LabelText } from "../../components";
import { FormDropdownI } from "../../types/formTypes";
import "./formDropdown.scss";

interface sortedDataI {
  value: string;
  label: string;
  id: string;
}

const FormDropdown: React.FC<FormDropdownI> = ({
  label,
  name,
  data,
  optionLabel,
  optionValue,
  className,
  emptyOptionText,
}) => {
  const [sortedData, setSortedData] = useState<sortedDataI[]>([]);
  const [isDropdownActive, setIsDropdownActive] = useState(false);

  useEffect(() => {
    const opts = data.map((item) => {
      let optionData: sortedDataI = {
        value: item[optionValue],
        label: item[optionLabel],
        id: v4(),
      };

      return optionData;
    });

    setSortedData(opts);
  }, [data, optionLabel, optionValue]);

  const toggleDropdown = () => setIsDropdownActive(!isDropdownActive);
  const closeDropdown = () => setIsDropdownActive(false);

  const classes = cs("formDropdown", { className });
  const dropdownClasses = cs("formDropdown__dropdown", {
    active: isDropdownActive === true,
  });

  return (
    <div className={classes}>
      {label && <LabelText id={name}>{label}</LabelText>}
      <Field name={name}>
        {({ field }: FieldProps) => {
          return (
            <>
              <div className="formDropdown__head" onClick={toggleDropdown}>
                {field.value ? field.value : emptyOptionText}
              </div>
              <div className={dropdownClasses}>
                {sortedData.map((item) => (
                  <div key={item.id} className="formDropdown__radio">
                    <input
                      className="formDropdown__input"
                      type="radio"
                      id={item.id}
                      {...field}
                      value={item.value}
                      checked={field.value === item.value}
                    />
                    <label
                      className="formDropdown__label"
                      htmlFor={item.id}
                      onClick={closeDropdown}
                    >
                      {item.label}
                    </label>
                  </div>
                ))}
              </div>
            </>
          );
        }}
      </Field>
      <ErrorMessage name={name} component={ErrorMsg} />
    </div>
  );
};

export default FormDropdown;
