import { Box } from "components";
import React, { ReactNode } from "react";
import cs from "classnames";
import MainHeader from "../header";
import "./mainContent.scss";

interface Props {
  header: ReactNode;
  children: React.ReactNode;
  fixedHeight?: boolean;
}

const MainContent: React.FC<Props> = ({ header, children, fixedHeight }) => {
  const containerClasses = cs("mainContent__container", {
    fixed: fixedHeight,
  });
  return (
    <Box as="main" className="mainContent">
      <MainHeader header={header} />
      <Box className={containerClasses}>{children}</Box>
    </Box>
  );
};

export default MainContent;
