import { Button } from "components";
import ContentMgtLayout from "layouts/contentMgt";
import "./updateWallet.scss";
import React, { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { IHttpError } from "interfaces/httpError";
import axios from "axios";
import { POWERFULL_MERCHANT_BASE_URL } from "constants/config";
import { getAccessToken } from "utils/localStorage";
import { MerchatTransaction } from "interfaces/shareable";
import queryClient from "config/reactQuery";

interface WalletInput {
  amount: number | "";
  reason: string;
  actionType: string;
  customReason?: string;
}
interface IProps {
  userId: string;
  transaction:MerchatTransaction
}
const UpdateWallet: FC<IProps> = ({ userId, transaction }) => {
  const [state, setState] = useState<WalletInput>({
    amount: "",
    reason: "",
    actionType: "",
    customReason: "",
  });



  const { isLoading, mutate: creditWallet } = useMutation(
    async (cred:{amount:number, smid:string}) => {
      return await axios.post<{amount:number}, null>(
        `${POWERFULL_MERCHANT_BASE_URL}customers/${cred.smid}/top-up`,
        {amount:cred.amount},
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()} `,
          },
        },
      
      );
      
    },
     
    {
      onSuccess(data) {
        queryClient.invalidateQueries(["wallet", transaction.user?.meterSerialNumber ]);
        toast.success("Top up successfull");

      },
      onError(error: IHttpError) {
        toast.error(error.response.data.message);
      },
    }
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!state.amount || state.amount <= 0) {
      toast.error("Enter a valid amount");
      return;
    }
    creditWallet({amount:state.amount, smid:transaction.user?.meterSerialNumber})
  
  };
  return (
    <div className="updateWallet">
      <h3 className="updateWallet__heading">Update user Wallet balance</h3>
      <form onSubmit={handleSubmit} className="updateWallet__form">
        <div className="updateWallet__row">
          <div className="updateWallet__col">
            <label className="updateWallet__label">Amount</label>
            <input
              placeholder="eg 10000"
              name="amount"
              value={state.amount}
              required
              onChange={handleChange}
              type="number"
            />
          </div>
         
        </div>
      

        <Button>{isLoading ? "Processing..." : "Update"}</Button>
      </form>
    </div>
  );
};

const reasons = [

  {
    label: "Failed Transaction and Money removed from Wallet",
    value: "Failed Transaction and Money removed from Wallet ",
  },
  {
    label:
      "Failed or Pending Transaction with Money refunded via Bank Transfer",
    value:
      "Failed or Pending Transaction with Money refunded via Bank Transfer",
  },
  {
    label: "Money received from Customer with No Transaction for Electricity Token",
    value:
      "Money received from Customer with No Transaction for Electricity Token",
  },
];

export default UpdateWallet;
