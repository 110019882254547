export enum ElectricityTransactionStatus {
  success = 'success',
  pending = 'pending',
  error = 'error',
}

export enum ElectricityReportStatus {
  daily= "dairly",
  weekly="weekly",
  monthly="monthly"
}

export enum TicketStatus  {
  notStarted = "not-started",
  started = "started",
  arhived = "archived"
}