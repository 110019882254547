import { Formik, Form } from "formik";
import { FormContainerI } from "../../types/formTypes";
import cs from "classnames";

const FormContainer: React.FC<FormContainerI> = ({
  validationSchema,
  initialValues,
  onSubmit,
  children,
  validateOnChange = true,
  validateOnBlur = true,
  className,
  ...props
}) => {
  const classes = cs("formContainer", {
    [`${className}`]: className,
  });
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={validateOnChange} // if false, it will prevent the form fields to be validated onChange
      validateOnBlur={validateOnBlur} // if false, it will prevent the form fields to be validated onBlur
      {...props}
    >
      <Form className={classes}>{children}</Form>
    </Formik>
  );
};

export default FormContainer;
