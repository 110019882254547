import React, { ReactNode } from "react";
import "./errorMsg.scss";

interface Props {
  children?: ReactNode;
}

const ErrorMsg: React.FC<Props> = ({ children }) => {
  return <div className="errorMsg"> {children}</div>;
};

export default ErrorMsg;
