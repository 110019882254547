import { Box, Button, SendMsgForm, Text } from "components";
import { FormikValues } from "formik";
import React from "react";
import "./details.scss";
import useUserWalletTransaction from "../../../../hooks/queries/useUserWalletTransaction";
import { toast } from "react-toastify";
import { formatCurrency, nairaSymbol } from "utils/formatCurrency";
import { MerchatTransaction, SparkCustomer } from "interfaces/shareable";
import { useQuery } from "react-query";
import axios from "axios";
import { POWERFULL_MERCHANT_BASE_URL } from "constants/config";
import { getAccessToken } from "utils/localStorage";

interface Props {
  walletBalance: number | undefined;
  walletBalanceLoading: boolean;
  condition: string;
  selectedCondition: string;
  handleUpdateCondition: () => void;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleSubmit: (values: FormikValues) => void;
  updateConditionLoading: boolean;
  messageLoading: boolean;
  transaction: MerchatTransaction;
}

const Details: React.FC<Props> = ({
  handleSubmit,
  messageLoading,
  transaction,
}) => {

  const { data:wallet } = useQuery(
    ["wallet", transaction.user?.meterSerialNumber ],
    async () => {
      const { data } = await axios.get<SparkCustomer>(
        `${POWERFULL_MERCHANT_BASE_URL}customerS/${transaction.user?.meterSerialNumber}`,
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()} `,
          },
        }
      );
      return data;
    }
  );

  const getWalletBalance = () => {
    
    return wallet?.data?.data?.credit_balance || 0;
  };

  const handleCopy = (value: string | number) => {
    if (!value) return;
    toast.success("copied to clipboard");
    window.navigator.clipboard.writeText(value.toString());
  };
  return (
    <Box className="details">
      <Box className="details__info">
        <Box className="details__box">
          <Text className="details__title">Name:</Text>
          <Text className="details__value">{transaction.user?.name}</Text>
        </Box>

        <Box className="details__box">
          <Text className="details__title">Email Address:</Text>
          <Text className="details__value">
            {transaction.user?.email}{" "}
            <button
              className="copyBtn"
              onClick={() => handleCopy(transaction.user?.email)}
            >
              Copy
            </button>
          </Text>
        </Box>
        <Box className="details__box">
          <Text className="details__title">Phone Number:</Text>
          <Text className="details__value">
            {" "}
            {transaction.user?.mobile}{" "}
            <button
              className="copyBtn"
              onClick={() => handleCopy(transaction.user?.mobile)}
            >
              Copy
            </button>
          </Text>
        </Box>
        <Box className="details__box">
          <Text className="details__title">Site:</Text>
          <Text className="details__value">{transaction?.site}</Text>
        </Box>
        <Box className="details__box">
          <Text className="details__title">Meter Number:</Text>
          <Text className="details__value">
            {transaction.user?.meterSerialNumber}{" "}
            <button
              className="copyBtn"
              onClick={() => handleCopy(transaction.user?.meterSerialNumber)}
            >
              Copy
            </button>
          </Text>
        </Box>
        <Box className="details__box">
          <Text className="details__title">Status:</Text>
          <Text className="details__value">{transaction.status}</Text>
        </Box>
        <Box className="details__box">
          <Text className="details__title">Amount:</Text>
          <Text className="details__value">
            {nairaSymbol}
            {formatCurrency(transaction.amount.toString())}
          </Text>
        </Box>

        <br />
      </Box>
      <Box className="details__wallet">
        <Text className="details__walletHeader">Wallet Balance</Text>
        <Text.H4 className="details__balance">N{formatCurrency(getWalletBalance().toString())}</Text.H4>
        <Box className="details__form">
          <SendMsgForm isLoading={messageLoading} handleSubmit={handleSubmit} />
        </Box>
      </Box>
    </Box>
  );
};

export default Details;
