import { Text } from "components/text";
import React from "react";
import cs from "classnames";
import "./statusAmount.scss";

interface Props {
  title: string;
  amount: number | string;
  onClick: () => void;
  isActive: boolean;
}

const StatusAmount: React.FC<Props> = ({
  amount,
  title,
  isActive,
  onClick,
}) => {
  const classes = cs("statusAmount", {
    active: isActive,
  });
  return (
    <button className={classes} onClick={onClick}>
      <Text.Small className="statusAmount__title">{title}</Text.Small>
      {/* <Text.Small className="statusAmount__amount">{amount}</Text.Small> */}
    </button>
  );
};

export default StatusAmount;
