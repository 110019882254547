import React, { ReactNode } from "react";
import cs from "classnames";
import "./label.scss";

interface Props {
  id: string;
  variant?: "white" | "black";
  children?: ReactNode;
}

const LabelText: React.FC<Props> = ({ children, id, variant = "black" }) => {
  const classes = cs("labelText", {
    "labelText--white": variant === "white",
    "labelText--black": variant === "black",
  });
  return (
    <label className={classes} htmlFor={id}>
      {children}
    </label>
  );
};

export default LabelText;
