import { ErrorMessage, Field, FieldProps } from "formik";
import { ErrorMsg } from "../../components";
import { FormCheckboxI } from "../../types/formTypes";
import cs from "classnames";
import "./formCheckbox.scss";
import { v4 } from "uuid";

const FormCheckbox: React.FC<FormCheckboxI> = ({ name, label, className }) => {
  const classes = cs("formCheckbox", { className });
  const id = v4();

  return (
    <div className={classes}>
      <Field name={name}>
        {({ field }: FieldProps) => {
          return (
            <div className="formCheckbox__checkbox">
              <input
                className="formCheckbox__input"
                type="checkbox"
                id={id}
                {...field}
              />
              <label className="formCheckbox__label" htmlFor={id}>
                {label}
              </label>
            </div>
          );
        }}
      </Field>
      <ErrorMessage name={name} component={ErrorMsg} />;
    </div>
  );
};

export default FormCheckbox;
