import { Box, PaginationBox, Skeleton, Text } from "components";
import React, { useState } from "react";
import cs from "classnames";
import "./otherTransactions.scss";
import { useQuery } from "react-query";
import axios from "axios";
import { MerchatTransaction } from "interfaces/shareable";
import { POWERFULL_MERCHANT_BASE_URL } from "constants/config";
import { getAccessToken } from "utils/localStorage";
import ContentMgtLayout from "layouts/contentMgt";
import moment from "moment";
import { formatCurrency, nairaSymbol } from "utils/formatCurrency";
import { Path } from "navigations/routes";
import { useNavigate } from "react-router-dom";

interface Props {
  user: string;
}


const OtherTransactions: React.FC<Props> = ({ user }) => {
  const navigate = useNavigate();
  const LIMIT = 50;
  const [currentPage, setCurrentPage] = useState(1);
  const { isLoading, data } = useQuery(
    ["merchant-transactions", user, currentPage],
    async () => {
      const { data } = await axios.get<{ data: MerchatTransaction[] }>(
        `${POWERFULL_MERCHANT_BASE_URL}/transactions/customer/${user}?skip=${currentPage}&limit=${LIMIT}`,
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()} `,
          },
        }
      );
      return data;
    }
  );

  const handleRoute = (id: string) =>
    navigate(`${Path.MERCHANT_TRANSACTIONS}/${id}`);

  return (
    <Box className="otherTransactions">
      <Box className="notificationTable">
        <Text.H3 className="notificationTable__headItem">
          Other transactions by this user
        </Text.H3>
        {isLoading ? (
          <Box>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Box>
        ) : (
          <Box>
            {data && !isLoading && (
              <div className="users__table">
                <Box className={cs("notificationTable__head")}>
                  <Box className="row">
                    <Text className="notificationTable__headItem">Name</Text>
                  </Box>

                  <Text className="notificationTable__headItem">Date</Text>
                  <Text className="notificationTable__headItem">Time</Text>
                  <Text className="notificationTable__headItem">Amount</Text>
                  <Text className="notificationTable__headItem">Disco</Text>
                  <Text className="notificationTable__headItem">
                    Phone Number
                  </Text>
                  {/* <Text className="notificationTable__headItem">Email</Text> */}
                  <Text className="notificationTable__headItem">Status</Text>
                </Box>
                <div className="success">
                  {data.data?.map((transaction) => (
                    <Box
                      className="notificationRow"
                      key={transaction._id}
                      onClick={() => handleRoute(transaction._id)}
                    >
                      <Box className="row" onClick={(e) => e.stopPropagation()}>
                        <Text.Small className="notificationRow__item success">
                          {transaction.user?.name}
                        </Text.Small>
                      </Box>

                      <Text.Small className="notificationRow__item">
                        {moment(transaction.createdAt).format("DD/MM/YYYY")}
                      </Text.Small>
                      <Text.Small className="notificationRow__item">
                        {moment(transaction.createdAt).format("h:mm a")}
                      </Text.Small>
                      <Text.Small className="notificationRow__item">
                        {nairaSymbol}
                        {formatCurrency(transaction.amount.toString())}
                      </Text.Small>
                      <Text.Small className="notificationRow__item">
                        {transaction.site}
                      </Text.Small>
                      <Text.Small className="notificationRow__item">
                        {transaction.user?.mobile}
                      </Text.Small>
                      <Text.Small className="notificationRow__item success">
                        {transaction.status}
                      </Text.Small>
                    </Box>
                  ))}
                </div>
              </div>
            )}
          </Box>
        )}

        <PaginationBox
          currentPage={currentPage}
          onPageClick={setCurrentPage}
          dataLength={data?.data?.length || 0}
          limit={LIMIT}
          shownDataLength={2}
        />
      </Box>
    </Box>
  );
};

export default OtherTransactions;
