import { TextProps } from "../../types/textTypes";
import "./sectionHeader.scss";

const SectionHeader: React.FC<TextProps> = ({ children, className }) => {
  return (
    <h2 className={`sectionHeader ${className ? className : ""}`}>
      {children}
    </h2>
  );
};

export default SectionHeader;
