import { AxiosConfig } from "config/AxiosConfig";
import { NewTransactionDataI } from "types/client";
import { sortData } from "utils/sortData";

const configValue = (token: string) => {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const getTransactions = async (
  token: string
): Promise<NewTransactionDataI[]> => {
  const config = configValue(token);

  const res = await AxiosConfig.get("/api/v1/txn", config);

  return sortData(res.data.data);
};

export const getDashboardTransactions = async (
  token: string
): Promise<NewTransactionDataI[]> => {
  const config = configValue(token);
  const res = await AxiosConfig.get("/api/v1/txn/unprocessed_txn", config);

  return sortData(res.data.data);
};

export const getTicketingTransactions = async (
  token: string
): Promise<NewTransactionDataI[]> => {
  const config = configValue(token);
  const res = await AxiosConfig.get("/api/v1/txn/log", config);

  return sortData(res.data.data);
};

export const getArchivedTransactions = async (
  token: string
): Promise<NewTransactionDataI[]> => {
  const config = configValue(token);
  const res = await AxiosConfig.get(
    "/api/v1/txn/log/getbystatus/Archived",
    config
  );

  return sortData(res.data.data);
};

export const getSingleTransaction = async (
  id: string | number,
  token: string
): Promise<NewTransactionDataI> => {
  const config = configValue(token);
  const res = await AxiosConfig.get(`/api/v1/txn/${id}`, config);

  return res.data?.data;
};

export const getPreviousTransactionByUser = async (
  id: string,
  token: string
): Promise<NewTransactionDataI[]> => {
  const config = configValue(token);
  const res = await AxiosConfig.get(`/api/v1/txn/previous/${id}`, config);

  return sortData(res.data.data);
};

export const updateCondition = async (
  id: string | number,
  status: string,
  token: string
) => {
  const config = configValue(token);
  const res = await AxiosConfig.post(`/api/v1/txn/${id}/${status}`, {}, config);

  return res.data?.data;
};

export const retryTransaction = async (reference: string, token: string) => {
  const config = configValue(token);
  const res = await AxiosConfig.get(`/api/v1/txn/retry/${reference}`, config);

  return res.data?.data;
};

export interface RefundTransactionPayload {
  amount: number;
  refundType: string;
}

interface RefundTransactionOptions {
  id: string;
  reference: string;
  token: string;
}

export const refundTransaction = async (
  payload: RefundTransactionPayload,
  options: RefundTransactionOptions
) => {
  const config = configValue(options.token);
  const res = await AxiosConfig.post(
    `/api/v1/txn/refund/${options.id}/${options.reference}`,
    payload,
    config
  );

  return res.data?.data;
};

export const getTransactionsByStatus = async (
  status: string,
  token: string
) => {
  const config = configValue(token);
  const res = await AxiosConfig.get(
    `/api/v1/log/getbystatus/${status}`,
    config
  );

  return sortData(res.data.data);
};
