import { AxiosConfig } from "config/AxiosConfig";
import { NewTransactionDataI } from "types/client";

const configValue = (token: string) => {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

export interface BroadcastMessageI {
  message: string;
  subject: string;
  userIds: (string | number)[];
  sendToAllUsers: boolean;
  channel: (string | number)[];
}

export const sendBroadcastMessage = async (
  payload: BroadcastMessageI,
  token: string
): Promise<NewTransactionDataI> => {
  const config = configValue(token);

  const res = await AxiosConfig.post(`/api/v1/txn/mail`, payload, config);

  return res.data?.data;
};
