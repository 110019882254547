import React, { useState } from "react";
import { Form, FormField, Box, Button } from "components";
import Editor, { ContentEditableEvent } from "react-simple-wysiwyg";
import { FormikValues } from "formik";
import "./sendMsgForm.scss";
import { UserI } from "types/client";
import * as yup from "yup";

export interface Data {
  name: string;
  value: string;
}
const data2 = [
  { name: "Email", value: "1" },
  { name: "Push Notification", value: "2" },
  { name: "SMS", value: "3" },
  { name: "WhatsApp", value: "4" },
];

interface Props {
  handleSubmit: (values: FormikValues) => void;
  users?: UserI[] | undefined;
  isLoading?: boolean;
}

const SendMsgForm: React.FC<Props> = ({ handleSubmit, isLoading }) => {
  const [channels, setChannels] = useState<string[]>([]);
  const [html, setHtml] = useState("Hello {{firstName}} {{lastName}}");
  const [smsChannel, setSmsChannel] = useState("generic");

  function onChange(e: ContentEditableEvent) {
    setHtml(e.target.value);
  }

  const validationSchema = yup.object().shape({
    channel: yup.array(),
  });

  const submitHandler = (formValues: any) => {
   
    const data = {
      ...formValues,
      message: html,
      smsChannel,
      channels
    };
    handleSubmit(data);
  };

  const handleChannel = (selected: boolean, value: string) => {
    if (selected) {
      setChannels((c) => c.concat(value));
    } else {
      const updated = channels.filter((item) => item !== value);

      setChannels(updated);
    }
  };

  const message = `Use {{firstName}} {{lastName}} to customize the template message`;

  return (
    <Form
      className="sendMsgForm"
      initialValues={{
        message: "",
        userIds: [],
        channel: [],
        subject: "",
      }}
      onSubmit={submitHandler}
      // validationSchema={validationSchema}
    >
      <p className="label">Broadcast Chennel</p>
      <div className="channels">
        {data2.map((channel) => (
          <label key={channel.value}>
            <input
              className="formCheckboxGroup__input"
              type="checkbox"
              onChange={(e) => handleChannel(e.target.checked, channel.value)}
              id={channel.value}
            />
            {channel.name}
          </label>
        ))}
      </div>
      <br />
      {channels.includes("3") && (
        <>
          <p className="label">SMS Chennel</p>
          <select
            className="input"
            value={smsChannel}
            onChange={(e) => setSmsChannel(e.target.value)}
            required
          >
            <option value="generic">Generic</option>
            <option value="dnd">DND</option>
            <option value="whatsapp">WhatsApp</option>
          </select>
        </>
      )}

      {channels.includes("1") && (
        <>
          <FormField name="subject" label="Subject" labelVariant="white" />
        </>
      )}

      <p className="label">Message</p>
      <p className="custom">{message}</p>

      <div className="editor">
        <Editor value={html} onChange={onChange} />
      </div>

      <Box>
        <Button loading={isLoading} fullWidth type="submit">
          {isLoading ? "Broadcasting Message" : "Send Message"}
        </Button>
      </Box>
    </Form>
  );
};

export default SendMsgForm;
