import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import {
  clearAccessToken,
  getRefreshToken,
  setAccessToken,
} from "utils/localStorage";
import { getAccessToken } from "utils/localStorage";
import { refreshTokenApi } from "api/auth";

const prodBaseUrl = "https://powerfull-mobile-app.herokuapp.com/api/v1/support";
const devBaseUrl = "http://localhost:5050/api/v1/support";
const baseURL =
  process.env.NODE_ENV === "development" ? prodBaseUrl : prodBaseUrl;

export const AxiosConfig = axios.create({
  baseURL,
});

// Add a request interceptor
AxiosConfig.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = await getAccessToken();
    if (token) {
      if (config.headers) config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return error;
  }
);

AxiosConfig.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalRequest = err.config;
    const refreshToken = getRefreshToken();
    const headers: AxiosRequestHeaders = {
      "X-REFRESH": refreshToken!,
    };
    if (err.response.status === 401) {
      clearAccessToken();
      window.location.href = "/login";
    }
    return Promise.reject(err);
  }
);
