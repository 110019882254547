import { Loader } from "components";
import { useAuth } from "contexts/AuthContext";
import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { Path } from "./routes";
import { getAccessToken } from "../utils/localStorage";
import MerchantPayin from "pages/merchantPayin";
const Login = lazy(() => import("pages/login"));
const Dashboard = lazy(() => import("pages/dashboard"));
const Notifications = lazy(() => import("pages/notifications"));
const Reporting = lazy(() => import("pages/reporting"));
const SingleNotification = lazy(() => import("pages/singleNotification"));
const Archived = lazy(() => import("pages/archived"));
const Ticketing = lazy(() => import("pages/ticketing"));
const Whatsapp = lazy(() => import("pages/whatsapp"));
const AppVersion = lazy(() => import("pages/settings/appVersion/AppVersion"));
const Users = lazy(() => import("pages/users/index"));
const UsersKyc = lazy(() => import("pages/usersKyc"));
const UserDetails = lazy(() => import("pages/userDetails/UserDetails"));
// const SingleWhatsapp = lazy(() => import("pages/singleWhatsapp"));
const NotFound = lazy(() => import("pages/notFound"));
const InactiveUsers = lazy(() => import("pages/inactiveUsers"));
const VirtualAccount = lazy(() => import("pages/bankAccounts"));
const MerchantTransactions = lazy(() => import("pages/merchantTransactions"));
const ReportDashboard = lazy(() => import("pages//reportDashboard"));
const ChartDashboard = lazy(() => import("pages/chart"));
const MerchantPayouts = lazy(() => import("pages/merchantPayouts"));

const RouterConfig = () => {
  const { state } = useAuth();
  // const isAuthenticated = getAccessToken();

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path={Path.HOME} element={<Navigate to={Path.LOGIN} />} />
        <Route path="/design" element={<ChartDashboard />} />
        <Route path={Path.LOGIN} element={<Login />} />
        <Route
          element={<ProtectedRoute isAuthenticated={state?.isAuthenticated!} />}
        >
          <Route path={Path.DASHBOARD} element={<Dashboard />} />
          <Route path={Path.TRANSACTIONS} element={<Notifications />} />
          <Route path={Path.REPORTING} element={<Reporting />} />
          <Route path={Path.ARCHIVED} element={<Archived />} />
          <Route path={Path.TICKETING} element={<Ticketing />} />
          <Route path={Path.WHATSAPP} element={<Whatsapp />} />
          <Route path={Path.SETTINGS} element={<AppVersion />} />
          <Route path={Path.USERS_DETAILS} element={<UserDetails />} />
          <Route path={Path.USERS} element={<Users />} />
          <Route path={Path.USERS_KYC} element={<UsersKyc />} />
          <Route path={Path.IN_USERS} element={<InactiveUsers />} />
          <Route path={Path.VIRTUAL_BANKS} element={<VirtualAccount />} />
          <Route
            path={Path.MERCHANT_TRANSACTIONS}
            element={<MerchantTransactions />}
          />
          <Route path={Path.MERCHANT_PAYOUTS} element={<MerchantPayouts />} />
          <Route path={Path.MERCHANT_PAYIN} element={<MerchantPayin />} />
          <Route
            path={Path.SINGLENOTIFICATION}
            element={<SingleNotification />}
          />
          <Route path={Path.REPORT_DASHBOARD} element={<ReportDashboard />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default RouterConfig;
