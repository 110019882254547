export enum Path {
  HOME = "/",
  LOGIN = "/login",
  DASHBOARD = "/dashboard",
  TRANSACTIONS = "/transactions",
  SINGLENOTIFICATION = "/transactions/:id",
  REPORTING = "/reporting",
  ARCHIVED = "/archived",
  TICKETING = "/ticketing",
  WHATSAPP = "/whatsapp",
  SINGLEWHATSAPP = "/whatsapp/:id",
  NOTFOUND = "/404",
  SETTINGS = "/settings",
  USERS = "/users",
  USERS_KYC = "/kyc-approval",
  USERS_DETAILS = "/users/:id",
  IN_USERS = "/inactive-accounts",
  VIRTUAL_BANKS = "/virtual-banks",
  MERCHANT_TRANSACTIONS = "/merchant-payins",
  MERCHANT_PAYIN = "/merchant-payins/:id",
  REPORT_DASHBOARD = "/report-dashboard",
  MERCHANT_PAYOUTS = "/merchant-payouts",
}
