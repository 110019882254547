import React, { useState, useEffect, FC, HTMLAttributes } from "react";
import "./checkbox.scss";

interface IProps {
  label?: string;
  checked: boolean;
}
const Checkbox: FC<IProps & React.HTMLProps<HTMLInputElement>> = ({
  label,
  name,
  checked,
  id,
  ...props
}) => {
  const [value, setValue] = useState(checked);
  useEffect(() => {
    setValue(checked);
  }, []);

  return (
    <div className="formCheckbox__checkbox">
      <input
        className="formCheckbox__input"
        type="checkbox"
        checked={checked}
        {...props}
      />
      <label className="formckeckbox__label text-xs" htmlFor={name || id}>
        {label}
      </label>
    </div>
  );
};
export default Checkbox;
