import {
  FormContainer,
  FormInput,
  FormTextarea,
  FormRadio,
  FormSelect,
  FormDropdown,
  FormCheckbox,
  FormCheckboxGroup,
  MultiStepWrapper,
  FormStep,
  FormFile,
} from "./modules";
import {
  FormCheckboxGroupI,
  FormCheckboxI,
  FormContainerI,
  FormDropdownI,
  FormFileI,
  FormInputI,
  FormRadioI,
  FormSelectI,
  FormStepI,
  FormTextareaI,
  MultiStepWrapperI,
} from "./types/formTypes";

// this is the container for the form fields
export const Form = (props: FormContainerI) => <FormContainer {...props} />;
Form.MultiStep = (props: MultiStepWrapperI) => <MultiStepWrapper {...props} />;
Form.Step = (props: FormStepI) => <FormStep {...props} />;

// this is the formfield component. It has embedded types
const FormField = (props: FormInputI) => <FormInput {...props} />;
FormField.Textarea = (props: FormTextareaI) => <FormTextarea {...props} />;
FormField.Radio = (props: FormRadioI) => <FormRadio {...props} />;
FormField.Select = (props: FormSelectI) => <FormSelect {...props} />;
FormField.Dropdown = (props: FormDropdownI) => <FormDropdown {...props} />;
FormField.Checkbox = (props: FormCheckboxI) => <FormCheckbox {...props} />;
FormField.File = (props: FormFileI) => <FormFile {...props} />;
FormField.CheckboxGroup = (props: FormCheckboxGroupI) => (
  <FormCheckboxGroup {...props} />
);

export default FormField;
