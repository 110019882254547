import { Box } from "components/box";
import React from "react";
import "./paginationBox.scss";

interface IProps {
currentPage: number;
limit: number;
onPageClick: (pageNumber:number) => void;
dataLength: number;
shownDataLength: number;
}

const PaginationBox: React.FC<IProps> = ({
  currentPage,
  onPageClick,
  dataLength,
  shownDataLength,
  limit,
}) => {
  const getNumberOfPages = () => {
    return Math.ceil(dataLength / limit);
  };
 
  return (
    <Box className="paginationBox">
      {/* <PaginationStats
        currentPage={currentPage}
        totalPages={getNumberOfPages()}
      /> */}
      <div className="paginationAction">
        <button disabled={currentPage <=1 } onClick={() => onPageClick(currentPage-1)}>Prev</button>
        <p>{currentPage}</p>
        <button disabled={dataLength < limit} onClick={() => onPageClick(currentPage+1)}>Next</button>
      </div>
    </Box>
  );
};

export default PaginationBox;
