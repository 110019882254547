import {IUser} from 'interfaces/user';

export const setCurrentUser =  (user: IUser | undefined) => {
  try {
    if (user) localStorage.setItem('@current_user', JSON.stringify(user));
  } catch (e) {
    // saving error
  }
};
export const getCurrentUser = (): IUser | undefined => {
  try {
    const value =  localStorage.getItem('@current_user');
    if (value !== null) {
      return JSON.parse(value);
    }
    return undefined;
  } catch (e) {
    // error reading value
  }
};

export const removeCurrentUser = async () => {
  try {
    await localStorage.removeItem('@current_user');
  } catch (e) {
    // saving error
  }
};

export const setAccessToken =  (token: string | undefined) => {
  try {
    if (token) localStorage.setItem('@access_tk', token);
  } catch (e) {
    // saving error
  }
};
export const getAccessToken =  () => {
  try {
    const value =  localStorage.getItem('@access_tk');
    if (value !== null) {
      return value;
    }
    return '';
  } catch (e) {
    // error reading value
  }
};
export const clearAccessToken =  () => {
   localStorage.removeItem('@access_tk');
};

export const setRefreshToken =  (token: string | undefined) => {
  try {
    if (token) localStorage.setItem('@refresh_tk', token);
  } catch (e) {
    // saving error
  }
};
export const getRefreshToken =  () => {
  try {
    const value =  localStorage.getItem('@refresh_tk');
    if (value !== null) {
      return value;
    }
    return '';
  } catch (e) {
    // error reading value
  }
};
export const clearRefreshToken =  () => {
   localStorage.removeItem('@refresh_tk');
};
export const setRememberMe =  () => {
  localStorage.setItem('@rememberMe', '1');
};


