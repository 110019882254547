import React from "react";
import { Link } from "react-router-dom";
import cs from "classnames";
import "./sideNavLink.scss";
import { IconType } from "react-icons";

interface Props {
  link: string;
  text: string;
  isActive: boolean;
  icon: IconType;
}

const SideNavLink: React.FC<Props> = ({ link, text, isActive, icon }) => {
  const classes = cs("sideNavLink", {
    active: isActive,
  });
  const Icon = icon;
  return (
    <Link className={classes} to={link}>
      <Icon className="sideNavLink__svg" />
      <span className="sideNavLink__text">{text}</span>
    </Link>
  );
};

export default SideNavLink;
