import { Box, Skeleton } from "components";
import ContentMgtLayout from "layouts/contentMgt";
import React, { useState } from "react";
import Details from "./components/details";

import OtherTransactions from "./components/otherTransactions";
import { useParams } from "react-router-dom";
import {
  useArchiveTicket,
  useUpdateTransaction,
} from "hooks/queries/transactions";
import { TransactionDataI } from "types/client";
import { FormikValues } from "formik";
import { BroadcastMessageI, sendBroadcastMessage } from "services/email";
import { useAuth } from "contexts/AuthContext";
// import moment from "moment";
// import { useGetUserWalletBalance } from "hooks/queries/users";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import axios from "axios";
import { POWERFULL_MERCHANT_BASE_URL } from "constants/config";
import { MerchatTransaction } from "interfaces/shareable";
import { getAccessToken } from "utils/localStorage";
import UpdateWallet from "./components/updateWallet/UpdateWallet";

const MerchantPayin = () => {
  const [transactions] = useState<TransactionDataI>();
  const [selectedCondition] = useState("");
  const [messageLoading, setMessageLoading] = useState(false);
  const params = useParams();


  const { state } = useAuth();

  const { isLoading, data } = useQuery(
    ["merchant-transaction", params.id],
    async () => {
      const { data } = await axios.get<{ transaction: MerchatTransaction }>(
        `${POWERFULL_MERCHANT_BASE_URL}transactions/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()} `,
          },
        }
      );
      return data;
    }
  );

 

  const { mutate, isLoading: updateConditionLoading } = useUpdateTransaction(
    params?.id || "",
    selectedCondition
  );



  const handleUpdateCondition = () => {
    if (!selectedCondition) {
      toast.error("Kindly select a condition");
    } else {
      mutate();
    }
  };


  const handleMsgSubmit = async (values: FormikValues) => {
    const payload: BroadcastMessageI = {
      message: values.message,
      subject: values.subject,
      // emailAddresses: ["stephenemmanuel770@gmail.com"],
      userIds: [transactions?.id || ""],
      sendToAllUsers: false,
      channel: [1],
    };

    setMessageLoading(true);
    try {
      const res = await sendBroadcastMessage(payload, state?.token || "");

      if (res) {
        toast.success("Message sent!");
        setMessageLoading(false);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "Something went wrong!");
      setMessageLoading(false);
    }
  };

  return (
    <ContentMgtLayout pageTitle="Transaction" header="Transaction">
      <Box className="singleNotification">
        {isLoading && (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        )}
        {!isLoading && (
          <>
            <Details
              //@ts-ignore
              transaction={data?.transaction}
              walletBalance={0}
              walletBalanceLoading={false}
              condition="Started"
           
              selectedCondition={selectedCondition}
              handleUpdateCondition={handleUpdateCondition}
              handleSubmit={handleMsgSubmit}
  
              messageLoading={messageLoading}
            />
            <UpdateWallet
             transaction={data?.transaction!}
              //@ts-ignore
              userId={data?.transaction?.user?._id}
            />
         
            <OtherTransactions
              //@ts-ignore
              user={data?.transaction?.user._id}
            />
          </>
        )}
      </Box>
    </ContentMgtLayout>
  );
};

export default MerchantPayin;
