import { FC } from "react"
import "./alert.scss"

interface IProps {
    message: string,
    type: "error" | "message" | "success"
}
const Alert:FC<IProps> = ({message, type}) => {

    return (
        <div className="alert">
            <p className={`alert__message alert__message--${type}`}>{message}</p>
        </div>
    )
}

export default Alert